import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from '../../../theme/Logo'
import HeaderNav from '../HeaderNav';
import { SocialLinks } from '../SocialLinks'
import './Header.scss';
import loadable from '@loadable/component'
import GatsbyLink from '../GatsbyLink'
import { throttle } from '../../utils/helpers'
const isClient = typeof window !== 'undefined';

class Header extends Component {
  state = {
    contactActive: false,
    navActive: false,
    scrollFromTop: false
  }

  componentDidMount() {
    window.addEventListener('resize', throttle(this.handleResize, 1000));
    window.addEventListener('scroll', throttle(this.handleScroll,10));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }


  handleScroll = (e) => {
    let topOffset = 20
    let scrollY = window.scrollY;
    if(window.scrollY > topOffset) {
      //console.log('scrolling down');
      this.setState({scrollFromTop: true})
    } else if (window.scrollY < topOffset){
      //console.log('scrolling up');
      this.setState({scrollFromTop: false})
    }
    if (window.scrollY <= 10){
      //console.log('at top');
      this.setState({scrollFromTop: false})
    }
    scrollY = window.scrollY;
  }



  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (window.innerWidth > 1080) {
      if(isClient) {
        let childrenDomElements = document.querySelectorAll('.children');
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains('open')) {
            childrenDomElements[i].classList.remove('open')
          }
        }
        if (document.body.classList.contains('nav-open')) {
          this.toggleNav();
        }
      }
    }
  }

  toggleNav = event => {
    const { navActive } = this.state;
    if (event) event.preventDefault();
    if (!navActive) {
      if (isClient) {
        document.body.classList.add('nav-open');
      }
      this.setState({
        contactActive: false,
        navActive: true,
      });
    } else {
      if (isClient) {
        document.body.classList.remove('nav-open');
      }
      this.setState({ navActive: false });
    }
  };

  closeAll = event => {
    if (event) event.preventDefault();
    if (isClient) {
      document.body.classList.remove('nav-open');
    }
    this.setState({
      navActive: false,
    });
  };

  render() {
    const {
      navActive,
      scrollFromTop
    } = this.state;
    const {
      data,
    } = this.props;
    const {
      primaryNavigation,
      siteSettings,
      metaData
    } = data;
    const { options } = siteSettings
    const { phone } = options.locations[0];
    const { items: navItems } = primaryNavigation
    return (
      <header className={scrollFromTop ? 'compact' : null}>
        <div className="header-main">
          <div className="wrap">
            <div className="inner">
              <Logo />
              <div className="nav">
                <div className="phone-container">
                  <span className="phone">
                    <a href={`tel:${phone}`}>{phone}</a>
                  </span>
                </div>
                <nav className={`nav-inner${navActive ? ' active' : ''}`}>
                  <HeaderNav active={navActive} className="navigation" navItems={navItems} toggleNav={this.toggleNav} />
                </nav>
                <div className="controls">
                  <button
                    type="button"
                    className={`control-nav icon-hamburger ${navActive ? 'active' : ''}`}
                    onClick={this.toggleNav}
                    aria-label="Toggle Menu"
                  ><div className={`burger${navActive ? ' active' : ''}`}><span className="inner" /></div></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        primaryNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "header-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              phone
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
