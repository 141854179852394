import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Hidden = (props) => {
  const { field, value, handleChange } = props
  const {type, name, placeholder, className } = field
  return (
    <div className="hidden">
      <input name={slugify(name)} id={slugify(name)} type="hidden" value={placeholder} />
    </div>
  )
}
