import React from 'react';
import Helmet from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import '../../sass/global/styles.scss';

const Layout = ({ children, location, wordpressUrl }) => (
  <div id="layout">
    <Header location={location} />
    <div className="main">{children}</div>
    <Footer location={location} />
  </div>
)

export default Layout
