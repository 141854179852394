import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import { StaticQuery, graphql, navigate } from 'gatsby';
import ReCAPTCHA from "react-google-recaptcha";
import * as FormFields from './Fields';
import './leads.scss'
import LazyLoad from 'react-lazy-load';

// Google TEST Keys
// Site key: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
// Secret key: 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe
class Index extends Component {

  // Create our ref for our Recaptcha component
  recaptchaRef = React.createRef();

  constructor(props) {
    const { thankYou = null, data, id = null, subscribe = null  } = props
    const { siteSettings, allWordpressWpForm } = data

    let settings = id && allWordpressWpForm && allWordpressWpForm.nodes.filter(i => i['wordpress_id'] === parseInt(id) )
    settings = settings && settings[0] && settings[0].formJson && settings[0].formJson


    super(props);

    this.state = {
      error: null,
      loading: false,
      submitted: false,
      ready: false,
      readyStatus: true,
      id: id,
      thankYou: thankYou,
      siteKey: siteSettings.options.googleSiteKey,
      settings: settings && settings.formFields && settings.formFields,
      lead: {
        location: typeof window !== `undefined` ? window.location.href : 'Server',
        recipientAddress: settings && settings.recipientAddress ? settings.recipientAddress : null,
        formId: id,
        [`newsletter-subscribe`]: subscribe
      },
      captchaToken: null,
      formHoveredOver: false,
    }
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    const { captchaToken } = this.state
    const token = await this.recaptchaRef.current.executeAsync();
    if (token) {
      this.setState({readyStatus: true})
      this.setState({captchaToken: captchaToken})
      this.captchaSubmit(e);
    } else {
      this.setState({readyStatus: false})
    }
  }
  captchaSubmit = async ( e ) => {
    this.setState({ loading: true });
    window.dataLayer = window.dataLayer || [];

    const leadFunctionUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/leads/v2/submit`
    const appSecret = process.env.GATSBY_WEB_APP_SECRET
    const { lead } = this.state
    const { location } = lead;
    /**
     * Submit our leads object to our WP leads handling endpoint
     * If you receive an error, double check the keys between this app and the website.
     * An invalid key on WP and in Gatsby may result in a 502.
     */
    fetch(leadFunctionUrl, {
      method: 'POST',
      body: JSON.stringify({secret: appSecret, lead: lead})
    })
    .then(response => {
      if (response.status === 200) {
        window.dataLayer.push({
          'event': 'form_submit_success',
          'formLocation': location
        });
        this.setState({ loading: false, submitted: true, lead: {} });
        this.props.thankYou && this.props.thankYou !== '' && navigate(this.props.thankYou)
      } else {
        this.setState({ loading: false, error: true, lead: {} });
      }
    })
    .catch(error => {
      console.error('submitForm Error', error)
      this.setState({ loading: false, error: true, lead: {} });
    });
  }

  //Form change
  handleChange(e) {
      let lead = this.state.lead
      //If file
      if (e.target.type === 'file') {

        let fileName = `lead-file-${e.target.name}`
        let file = e.target.files[0];
        lead[fileName] = { data: '', file: '' }

        if (file) {
          const reader = new FileReader(file);
          reader.readAsDataURL(file);
          reader.onload = () => {
            // set image and base64'd image data in component state
            lead[fileName].data = reader.result
            lead[fileName].file = file.name
          }
        }
      } else if ( e.target.type === 'checkbox' ) {
        //Toggle value on off
        lead[e.target.name] === e.target.value ? lead[e.target.name] = '' : lead[e.target.name] = e.target.value
      } else {
        //All other fields
        lead[e.target.name] = e.target.value
      }
      this.setState({lead})
  }

  handleFormHover() {
    if (!this.state.formHoveredOver) {
      this.setState({formHoveredOver: true})
    } else {
      return null;
    }
  }


  render() {
    const { submitButton = 'Get started today', thankYouText = '<h3>Thank you!</h3><p>Your message has been sent.</p>' } = this.props
    const { loading, submitted, error, lead, siteKey, id, settings, readyStatus, formHoveredOver } = this.state

    if (!id) return 'No form id!'

    if (!settings) return 'There is a problem with the JSON!';

    if (submitted) return <div className="lead-form thank-you" dangerouslySetInnerHTML={{__html:thankYouText}} />

    const formatComponentName = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
      <div className="lead-form" onMouseEnter={() => this.handleFormHover()} onTouchStart={() => this.handleFormHover()}>
        {error && <div className="error"><div>There was an error submitting the form.</div></div>}
        {loading && <div className="loading"><div><div className="spin"/>Loading...</div></div>}
        <form className="lead-form-inner" onSubmit={event => {this.handleSubmit(event)}}>
          <div className="form-inner">
            {settings.map((field, index) => {
              const FormComponent = FormFields[formatComponentName(field.type)];
              // Map each field into the form component
              return (
                <FormComponent key={index} field={field} value={lead} handleChange={e => this.handleChange(e)} />
              )
            })}
            <div className="captcha-wrapper">
              <button disabled={!readyStatus} className={`submit${this.state.ready ? ' ready' : ' ready'}`}>{submitButton}</button>
            </div>
          </div>
          {formHoveredOver ? (
            <ReCAPTCHA
              size="invisible"
              ref={this.recaptchaRef}
              className="invisible-recaptcha"
              style={{ opacity: 0 }} // we don't want the recaptcha icon to appear across the site
              sitekey={siteKey}
            />
          ) : (
            null
          )
          }
        </form>
      </div>
    )
  }
}


export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpSettings {
          title
          siteUrl
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            googleSiteKey
          }
        },
        allWordpressWpForm {
          nodes {
            wordpress_id
            formJson {
              recipientAddress
              formFields {
                type
                required
                placeholder
                options {
                  label
                  value
                }
                name
                className
              }
            }
          }
        }
      }
    `}
    render={data => <Index data={data} {...props} />}
  />
)
