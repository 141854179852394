import React from 'react';
import RenderContent from '../RenderContent';
import Helmet from 'react-helmet';
import LazyLoad from 'react-lazy-load'
import Leads from '../Leads'

const NewsletterSignUp = ({formId = ''}) => (
  <LazyLoad offsetBottom={100} debounce={false}>
    <div className="newsletter-form">
      <Leads thankYouText="<p>Thank you for subscribing.</p>" id="15" submitButton="Subscribe" subscribe="yes"/>
    </div>
  </LazyLoad>
)

export default NewsletterSignUp
