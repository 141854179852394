import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from '../../../theme/LogoFooter';
import GatsbyLink from '../GatsbyLink';
import RenderContent from '../RenderContent';
import { SocialLinks } from '../SocialLinks'
import './Footer.scss';
import { decodeEntities } from '../../utils/helpers';
const isClient = typeof window !== 'undefined';
import loadable from '@loadable/component'
import NewsletterSignUp from '../NewsletterSignUp'

const CookieNotice = (props) => {
  const { privacyCookiePolicyNotice, privacyCookiePolicyNoticeText } = props.options
  if (privacyCookiePolicyNotice) {
    const CookiePolicy = loadable(() => import(`../CookiePolicy/index.js`))
    return <CookiePolicy content={privacyCookiePolicyNoticeText}/>
  }
  return null
}

export const sendEmail = (event, email) => {
  event.preventDefault()
  window.location = `mailto:${decodeEntities(email)}`;
}

const Footer = (props) => {
  const { data, location = {} } = props;
  const {
    legalNavigation,
    footerNavigation,
    siteSettings,
    wordpressWpSettings
  } = data;
  const { items: legalNav } = legalNavigation;
  const { items: footerNav } = footerNavigation
  const { footerTagline, navigationInFooter, contactDetailsInFooter, privacyCookiePolicyNotice, privacyCookiePolicyNoticeText } = siteSettings.options
  const {
    email,
    phone,
    addressDisplay
  } = siteSettings.options.locations[0];
  const currentYear = (new Date()).getFullYear();

  return (
    <>
    <footer>
      <div className="footer-main">
        <div className="inner">
          <div className="wrap">

            <div className="logos">
              <Logo />
              {footerTagline && <RenderContent className="tagline" content={footerTagline}/>}
            </div>

            <div className="footer-nav">
              <span className="h4">More<br/>info</span>
              <ul className="">
              {footerNav  && footerNav.map((item, index) => (
                <li key={index}><GatsbyLink to={item.url} aria-label={item.title} decode={true}>{item.title}</GatsbyLink></li>
              ))
              }
              </ul>
            </div>

            <div className="contact-details">
              <span className="h4">Scope<br/>Advisory</span>
              {addressDisplay && <div className="address" dangerouslySetInnerHTML={{__html: addressDisplay.split(', ').join('</br>')}} />}
              {phone && <div><a
                href={`tel:${phone.replace(/[^A-Z0-9]/ig, "")}`}
                className='control-call'
                aria-label="Call Now"
              >{phone}</a></div>}
              {email && <div><a href="#" className="email" onClick={(event) => sendEmail(event, email)} dangerouslySetInnerHTML={{__html: email}} /></div>}
            </div>



            <div className="newsletter">
              <span className="h4">Newsletter<br/>signup</span>
              <NewsletterSignUp formId=""/>
            </div>

          </div>
        </div>
      </div>

      <div className="disclaimers">
         <div className="wrap">
            {siteSettings.options.socialLinksFooter && <SocialLinks/>}
            <div className="right">
              <div className="copyright">
                <span>©{currentYear} <strong>{wordpressWpSettings.title}</strong>, All rights reserved</span>
              </div>

              {legalNav &&
                <div className="navigation">
                  <ul>
                    {legalNav.map((item, index) => (
                      <li key={index}><GatsbyLink to={item.url} decode={true}>{item.title}</GatsbyLink></li>
                    ))}
                  </ul>
                </div>
              }
              
              <div className="licensee">
                <div dangerouslySetInnerHTML={{__html:siteSettings.options.disclaimer}} />
                {location.pathname === "/" ? (
                  <p className="distl">
                    <span>
                      Website by{" "}
                      <a
                        rel="noopener"
                        target="_blank"
                        href="https://distl.com.au/"
                      >
                        Distl
                      </a>
                    </span>
                  </p>
                ) : (
                  <p className="distl">
                    <span>Website by Distl</span>
                  </p>
                )}
              </div>
            </div>
         </div>
       </div>
    </footer>
    <CookieNotice options={siteSettings.options}/>
    </>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "legal-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        },
        footerNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "footer-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        },
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
            footerTagline
            disclaimer
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        },
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
